import React from "react"

export default (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    viewBox="0 0 1216 296"
    enableBackground="new 0 0 1216 296"
    {...props}
  >
    {/* Yellow squares  */}
    <rect x="17.69" y="24.6" fill="#FFF200" width="39.12" height="39.12" />
    <rect x="487.92" y="227.88" fill="#FFF200" width="39.13" height="39.12" />
    <rect x="1132.57" y="84.41" fill="#FFF200" width="39.12" height="39.12" />
    {/* end of squares */}
    <g>
      <path
        d="M87.28,104.11h16.37c0.25,13.79,9.48,17.48,21.67,17.48c11.45,0,17.73-4.43,17.73-12.68c0-6.89-4.56-9.97-17.98-12.68
		l-7.39-1.48c-16.99-3.2-28.19-10.71-28.19-26.59c0-14.53,11.45-24.74,32.5-24.74c24.62,0,35.09,11.57,35.7,28.07h-16.13
		c-0.61-9.36-5.42-14.65-19.33-14.65c-10.59,0-15.76,4.19-15.76,10.83c0,7.02,4.06,10.22,17.11,12.93l7.63,1.48
		c20.68,4.06,29.05,12.07,29.05,25.98c0,17.36-13.54,26.96-35.46,26.96C101.43,135.01,87.4,124.3,87.28,104.11z"
      />
      <path
        d="M237.95,45.39l-32.87,53.06v34.59h-16.62V98.45l-33.12-53.06h18.84l9.73,15.88c4.43,7.39,8.99,14.77,13.17,22.16
		c4.19-7.51,8.62-15.02,12.93-22.28l9.6-15.76H237.95z"
      />
      <path
        d="M317.47,45.39v87.65H299l-24.38-39.03c-5.54-8.74-11.2-17.85-15.64-26.34c0.49,15.51,0.49,29.55,0.49,42.47v22.9h-16.01
		V45.39h18.47l24.5,39.03c4.92,8,11.08,18.1,15.51,26.34c-0.37-15.51-0.49-29.55-0.49-42.47v-22.9H317.47z"
      />
      <path
        d="M328.16,89.21c0-28.31,17.11-45.8,42.47-45.8c19.45,0,35.95,10.46,39.76,32.13h-16.87c-3.08-12.31-12.19-17.6-22.9-17.6
		c-15.64,0-25.12,11.7-25.12,31.27c0,19.7,9.48,31.27,25.12,31.27c11.2,0,21.54-6.65,23.51-20.44h16.62
		c-2.46,23.14-20.07,34.96-40.13,34.96C345.64,135.01,328.16,117.53,328.16,89.21z"
      />
      <path d="M495.09,45.39v87.65h-16.62V95.62h-40.75v37.43H421.1V45.39h16.62V80.6h40.75V45.39H495.09z" />
      <path
        d="M581.5,133.04h-17.11c-1.6-2.59-2.22-6.16-2.34-10.46l-0.37-9.6c-0.49-11.2-5.17-14.28-14.03-14.28h-21.67v34.35h-16.62
		V45.39h39.77c20.19,0,30.9,9.97,30.9,24.74c0,10.09-5.17,17.6-14.65,20.93c9.73,3.08,12.31,9.6,12.68,18.71l0.74,13.05
		C578.91,126.64,579.77,130.46,581.5,133.04z M548.13,85.64c8.99,0,14.77-4.68,14.77-13.67c0-8.62-5.79-12.93-15.39-12.93h-21.54
		v26.59H548.13z"
      />
      <path
        d="M587.51,89.21c0-27.58,17.48-45.8,43.83-45.8c26.22,0,43.7,18.22,43.7,45.8s-17.48,45.8-43.7,45.8
		C605,135.01,587.51,116.79,587.51,89.21z M657.81,89.21c0-19.33-10.46-31.15-26.47-31.15s-26.47,11.82-26.47,31.15
		c0,19.33,10.46,31.15,26.47,31.15S657.81,108.54,657.81,89.21z"
      />
      <path
        d="M759.73,45.39v87.65h-18.47l-24.38-39.03c-5.54-8.74-11.2-17.85-15.64-26.34c0.49,15.51,0.49,29.55,0.49,42.47v22.9h-16.01
		V45.39h18.47l24.5,39.03c4.92,8,11.08,18.1,15.51,26.34c-0.37-15.51-0.49-29.55-0.49-42.47v-22.9H759.73z"
      />
      <path d="M774.12,45.39h16.62v87.65h-16.62V45.39z" />
      <path
        d="M801.44,89.21c0-28.31,17.11-45.8,42.47-45.8c19.45,0,35.95,10.46,39.76,32.13h-16.87c-3.08-12.31-12.19-17.6-22.9-17.6
		c-15.64,0-25.11,11.7-25.11,31.27c0,19.7,9.48,31.27,25.11,31.27c11.2,0,21.54-6.65,23.51-20.44h16.62
		c-2.46,23.14-20.07,34.96-40.13,34.96C818.93,135.01,801.44,117.53,801.44,89.21z"
      />
      <path d="M894.38,45.39H911v87.65h-16.62V45.39z" />
      <path d="M992.37,60.28H964.3v72.76h-16.74V60.28h-28.07v-14.9h72.88V60.28z" />
      <path
        d="M1076.32,45.39l-32.87,53.06v34.59h-16.62V98.45l-33.12-53.06h18.84l9.73,15.88c4.43,7.39,8.99,14.77,13.17,22.16
		c4.19-7.51,8.62-15.02,12.93-22.28l9.6-15.76H1076.32z"
      />
      <path
        d="M642.62,157.29l-31.39,87.65h-19.45l-32.13-87.65h17.73l15.88,45.3c2.83,8.62,5.79,17.97,8.37,27.33
		c2.46-9.36,5.42-18.71,8.25-27.33l15.51-45.3H642.62z"
      />
      <path d="M666.36,172.18v20.68h43.46v14.28h-43.46v22.9h47.77v14.9h-64.39v-87.65h64.63v14.9H666.36z" />
      <path
        d="M798.57,157.29v87.65h-18.47l-24.38-39.03c-5.54-8.74-11.2-17.85-15.64-26.34c0.49,15.51,0.49,29.55,0.49,42.47v22.9h-16
		v-87.65h18.47l24.5,39.03c4.92,8,11.08,18.1,15.51,26.34c-0.37-15.51-0.49-29.55-0.49-42.47v-22.9H798.57z"
      />
      <path d="M879.93,172.18h-28.07v72.76h-16.74v-72.76h-28.07v-14.9h72.88V172.18z" />
      <path
        d="M886.2,211.95v-54.66h16.62v54.78c0,13.79,7.51,20.31,20.07,20.31c12.56,0,20.07-6.52,20.07-20.31v-54.78h16.62v54.66
		c0,22.78-14.16,34.84-36.69,34.84C900.85,246.79,886.2,234.73,886.2,211.95z"
      />
      <path
        d="M1044.75,244.94h-17.11c-1.6-2.59-2.22-6.16-2.34-10.46l-0.37-9.6c-0.49-11.2-5.17-14.28-14.03-14.28h-21.67v34.35h-16.62
		v-87.65h39.77c20.19,0,30.9,9.97,30.9,24.74c0,10.09-5.17,17.6-14.65,20.93c9.73,3.08,12.31,9.6,12.68,18.71l0.74,13.05
		C1042.17,238.54,1043.03,242.36,1044.75,244.94z M1011.39,197.55c8.99,0,14.77-4.68,14.77-13.67c0-8.62-5.79-12.93-15.39-12.93
		h-21.54v26.59H1011.39z"
      />
      <path d="M1071.08,172.18v20.68h43.46v14.28h-43.46v22.9h47.77v14.9h-64.39v-87.65h64.63v14.9H1071.08z" />
      <path
        d="M1125,216.01h16.37c0.25,13.79,9.48,17.48,21.67,17.48c11.45,0,17.73-4.43,17.73-12.68c0-6.89-4.56-9.97-17.98-12.68
		l-7.39-1.48c-16.99-3.2-28.19-10.71-28.19-26.59c0-14.53,11.45-24.74,32.5-24.74c24.62,0,35.09,11.57,35.7,28.07h-16.13
		c-0.61-9.36-5.42-14.65-19.33-14.65c-10.59,0-15.76,4.19-15.76,10.83c0,7.02,4.06,10.22,17.11,12.93l7.63,1.48
		c20.68,4.06,29.05,12.07,29.05,25.98c0,17.36-13.54,26.96-35.46,26.96C1139.15,246.91,1125.12,236.2,1125,216.01z"
      />
    </g>
  </svg>
)
