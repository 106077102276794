import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/Logo"

const HeroSection = styled.div`
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  #logo {
    width: 730px;
  }
  @media only screen and (max-width: 820px) {
    height: 80vh;
  }
`

const IndentedSection = styled.div`
  margin-left: 50px;
  @media only screen and (max-width: 820px) {
    margin-left: 20px;
  }
`

const P = styled.p`
  margin: 0;
  font-size: 45px;
  line-height: normal;
  letter-spacing: 0.8px;
  line-height: 49px;
  @media only screen and (max-width: 820px) {
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.4px;
  }
`

const A = styled.a`
  margin: 0;
  font-size: 45px;
  line-height: normal;
  letter-spacing: 0.8px;
  @media only screen and (max-width: 820px) {
    font-size: 15px;
    letter-spacing: 0.4px;
  }
`

const TextSection = styled.div`
  margin-bottom: 160px;
  @media only screen and (max-width: 700px) {
    margin-bottom: 60px;
  }
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
`

const CopyWright = styled.p`
  margin: 0;
  font-size: 45px;
  line-height: normal;
  letter-spacing: 0.8px;
  @media only screen and (max-width: 820px) {
    font-size: 15px;
    letter-spacing: 0.4px;
  }
`

const IndexPage = () => (
  <Layout hideFooter>
    <SEO
      title="Home"
      meta={[
        {
          name: "keywords",
          content:
            "Wellness & Beauty, Consumer technology, Incubator, Early-stage, Beauty, Wellness, Food, Beverage, Consumer technology",
        },
      ]}
    />
    <HeroSection>
      <Logo id="logo" />
    </HeroSection>
    <TextSection>
      <P>Synchronicity</P>
      <IndentedSection>
        <P>
          Meaningful coincidences, the simultaneous occurrence of events that
          seem to be meaningfully connected. Risky or daring journeys or
          undertakings.{" "}
        </P>
      </IndentedSection>
      <P>Ventures</P>
      <IndentedSection>
        <P>Risky or daring journeys or undertakings.</P>
      </IndentedSection>
    </TextSection>
    <TextSection>
      <P>
        We are an early-stage incubator that helps launch, build, and scale the
        next generation of great consumer brands. We focus on early-stage,
        purpose-driven brands that are tapping into today’s consumer beliefs and
        behaviors to lead new solutions to improve people’s wellbeing. We
        incubate brands in three key verticals:
      </P>
      <IndentedSection>
        <P>
          Beauty and wellness
          <br />
          Food and beverage
          <br />
          Consumer technology
        </P>
      </IndentedSection>
    </TextSection>
    <A href="mailto:connect@synchronicityventures.com">
      connect@synchronicityventures.com
    </A>
    <CopyWright>
      Copyright © {new Date().getFullYear()} synchronicityventures
    </CopyWright>
  </Layout>
)

export default IndexPage
